import React from "react";
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import FacebookIcon from '@mui/icons-material/Facebook';
import teamImg1 from "../../../images/team/team4.jpg";
import teamImg2 from "../../../images/team/team5.jpg";
import teamImg3 from "../../../images/team/team6.jpg";
import teamImg4 from "../../../images/team/team7.jpg";
import { Face } from "@mui/icons-material";
const TeamCardTwo = () => {
  return (
    <>
      <div id="team" className="team-section bg-color pt-100 pb-70">
        <div className="container">
          <div className="section-title">
            <span>Team</span>
            <h3>Meet Our Team</h3>
          </div>

          <div className="row justify-content-center">
            <div 
              className="col-lg-4 col-md-6"
              data-aos="fade-up"
              data-aos-delay="100"
              data-aos-duration="800"
              data-aos-once="true"
            >
              <div className="team-item">
                <div className="image">
                  <img src={teamImg1} alt="image" width={510} height={500} />

                  <ul className="social">
                    {/* <li>
                      <a href="https://www.twitter.com/" target="_blank">
                        <i className="fab fa-twitter"></i>
                      </a>
                    </li> */}
                    <li>
                      <a href="https://www.linkedin.com/in/anniegul/" target="_blank">
                      <LinkedInIcon sx={{mt:'5px'}}/>
                      </a>
                    </li>
                    <li>
                      <a href="https://www.facebook.com/ianniegul" target="_blank">
                      <FacebookIcon sx={{mt:'5px'}}/>
                      </a>
                    </li>
                    {/* <li>
                      <a href="https://www.instagram.com/" target="_blank">
                        <i className="fab fa-instagram"></i>
                      </a>
                    </li> */}
                  </ul>
                </div>
                <div className="content bg-ffffff">
                  <h3>Annie Gul</h3>
                  <span>Founder and CEO</span>
                </div>
              </div>
            </div>

            <div 
              className="col-lg-4 col-md-6"
              data-aos="fade-up"
              data-aos-delay="200"
              data-aos-duration="800"
              data-aos-once="true"
            >
              <div className="team-item">
                <div className="image">
                  <img src={teamImg2} alt="image" width={510} height={500} />

                  <ul className="social">
                    {/* <li>
                      <a href="https://www.twitter.com/" target="_blank">
                        <i className="fab fa-twitter"></i>
                      </a>
                    </li> */}
                    <li>
                      <a href="https://www.linkedin.com/in/ehsanullah2/" target="_blank">
                      <LinkedInIcon sx={{mt:'5px'}}/>
                      </a>
                    </li>
                    <li>
                      <a href="https://www.facebook.com/ehsanfaceb" target="_blank">
                      <FacebookIcon sx={{mt:'5px'}}/>
                      </a>
                    </li>
                    {/* <li>
                      <a href="https://www.instagram.com/" target="_blank">
                        <i className="fab fa-instagram"></i>
                      </a>
                    </li> */}
                  </ul>
                </div>
                <div className="content bg-ffffff">
                  <h3>Ehsan Ullah</h3>
                  <span>Game Developer</span>
                </div>
              </div>
            </div>

            <div 
              className="col-lg-4 col-md-6"
              data-aos="fade-up"
              data-aos-delay="300"
              data-aos-duration="800"
              data-aos-once="true"
            >
              <div className="team-item">
                <div className="image">
                  <img src={teamImg3} alt="image" width={510} height={500} />

                  <ul className="social">
                    {/* <li>
                      <a href="https://www.twitter.com/" target="_blank">
                        <i className="fab fa-twitter"></i>
                      </a>
                    </li> */}
                    <li>
                      <a href="https://www.linkedin.com/in/usmannullah/" target="_blank">
                        <LinkedInIcon sx={{mt:'5px'}}/>
                      </a>
                    </li>
                    <li>
                      <a href="https://www.facebook.com/enostracevia/" target="_blank">
                      <FacebookIcon sx={{mt:'5px'}}/>
                      </a>
                    </li>
                    {/* <li>
                      <a href="https://www.instagram.com/" target="_blank">
                        <i className="fab fa-instagram"></i>
                      </a>
                    </li> */}
                  </ul>
                </div>
                <div className="content bg-ffffff">
                  <h3>Usman Ullah</h3>
                  <span>Web Developer</span>
                </div>
              </div>
            </div>
            <div 
              className="col-lg-4 col-md-6"
              data-aos="fade-up"
              data-aos-delay="300"
              data-aos-duration="800"
              data-aos-once="true"
            >
              <div className="team-item">
                <div className="image">
                  <img src={teamImg4} alt="image" width={510} height={500} />

                  <ul className="social">
                    {/* <li>
                      <a href="https://www.twitter.com/" target="_blank">
                        <i className="fab fa-twitter"></i>
                      </a>
                    </li> */}
                    <li>
                      <a href="https://www.linkedin.com/in/hzaheer48/" target="_blank">
                      <LinkedInIcon sx={{mt:'5px'}}/>
                      </a>
                    </li>
                    {/* <li>
                      <a href="https://www.facebook.com/" target="_blank">
                        <i className="fab fa-facebook-f"></i>
                      </a>
                    </li>
                    <li>
                      <a href="https://www.instagram.com/" target="_blank">
                        <i className="fab fa-instagram"></i>
                      </a>
                    </li> */}
                  </ul>
                </div>
                <div className="content bg-ffffff">
                  <h3>Hammad Zaheer</h3>
                  <span>Senior Web Developer</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TeamCardTwo;
