import React, { useState, useEffect } from "react";
import { Link } from "react-scroll";

import logo from "../../../images/logo.png";


const Navbar = () => {
  // Add active class
  const [currentPath, setCurrentPath] = useState("");
  // const router = useRouter();
  // console.log(router.asPath)


  const [menu, setMenu] = React.useState(true);
  const toggleNavbar = () => {
    setMenu(!menu);
  };
  React.useEffect(() => {
    let elementId = document.getElementById("navbar");
    document.addEventListener("scroll", () => {
      if (window.scrollY > 170) {
        elementId.classList.add("is-sticky");
      } else {
        elementId.classList.remove("is-sticky");
      }
    });
  });

  // Search Modal
  const [isActiveSearchModal, setActiveSearchModal] = useState("false");
  const handleToggleSearchModal = () => {
    setActiveSearchModal(!isActiveSearchModal);
  };

  // Sidebar Modal
  const [isActiveSidebarModal, setActiveSidebarModal] = useState("false");
  const handleToggleSidebarModal = () => {
    setActiveSidebarModal(!isActiveSidebarModal);
  };

  const classOne = menu
    ? "collapse navbar-collapse mean-menu"
    : "collapse navbar-collapse show";
  const classTwo = menu
    ? "navbar-toggler navbar-toggler-right collapsed"
    : "navbar-toggler navbar-toggler-right";

  return (
    <>
      <div id="navbar" className="navbar-area navbar-two">
        <div className="neemo-nav">
          <div className="container">
            <nav className="navbar navbar-expand-md navbar-light">
              <Link href="/" className="navbar-brand">
                <img src={logo} alt="logo" width={150} height={34} />
              </Link>

              {/* Toggle navigation */}
              <button
                onClick={toggleNavbar}
                className={classTwo}
                type="button"
                data-toggle="collapse"
                data-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span className="icon-bar top-bar"></span>
                <span className="icon-bar middle-bar"></span>
                <span className="icon-bar bottom-bar"></span>
              </button>

              <div className={classOne} id="navbarSupportedContent">
                <ul className="navbar-nav">
                  
                  <li className="nav-item">
                    <Link
                      activeClass="active" 
                      to="about-section " 
                      spy={true} 
                      smooth={true} 
                      offset={50} 
                      duration={500} 
                      style={{cursor:'pointer'}}
                    >
                      About us
                    </Link>
                  </li>

                  <li className="nav-item">
                    <Link
                      activeClass="active" 
                      to="services" 
                      spy={true} 
                      smooth={true} 
                      offset={50} 
                      duration={500} 
                      style={{cursor:'pointer'}}
                    >
                      Services 
                    </Link>

                    
                  </li>

                  <li className="nav-item">
                    <Link
                      activeClass="active" 
                      to="projects" 
                      spy={true} 
                      smooth={true} 
                      offset={50} 
                      duration={500} 
                      style={{cursor:'pointer'}}
                    >
                      Projects 
                    </Link>

                    
                  </li>
                  <li className="nav-item">
                    <Link
                      activeClass="active" 
                      to="team" 
                      spy={true} 
                      smooth={true} 
                      offset={50} 
                      duration={500} 
                      style={{cursor:'pointer'}}
                    >
                     Team
                    </Link>

                  </li>

                  {/* <li className="nav-item">
                    <Link
                      href="#"
                      className="nav-link"
                      onClick={(e) => e.preventDefault()}
                    >
                      Blog 
                    </Link>

                  </li>

                

                  <li className="nav-item">
                    <Link
                      href="/contact-us/"
                      className={`nav-link ${
                        currentPath == "/contact-us/" && "active"
                      }`}
                    >
                      Contact
                    </Link>
                  </li> */}
                </ul>
              </div>

              
            </nav>
          </div>
        </div>
      </div>

     

    </>
  );
};

export default Navbar;
