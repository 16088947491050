import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation } from "swiper/modules";

import image1 from '../../../images/slider-bg.jpg'
import image2 from '../../../images/slider-bg2.jpg'
import image3 from '../../../images/slider-bg3.jpg'
const MainBanner = () => {
  return (
    <>
      <Swiper 
        autoHeight={true}
        navigation={true} 
        autoplay={{
          delay: 6500,
          disableOnInteraction: true,
          pauseOnMouseEnter: true,
        }}
        modules={[Autoplay, Navigation]} 
        className="home-slides"
      >
        <SwiperSlide>
          <div 
            className="main-banner-item"
            style={{
              backgroundImage: `url(${image1})`,
        backgroundPosition:'center bottom',
        boxShadow:'inset 0 0 0 2000px rgba(0, 0,0, 0.6)'

    
            }}
          >
            <div className="d-table">
              <div className="d-table-cell">
                <div className="container">
                  <div className="main-banner-content">
                    <h1 >"Crafting a tech-empowered future for our youth"</h1>
                
                  </div>
                </div>
              </div>
            </div>
          </div>  
        </SwiperSlide>
        <SwiperSlide>
          <div 
            className="main-banner-item"
            style={{
              backgroundImage: `url(${image2})`,
              backgroundSize:'cover',
              boxShadow:'inset 0 0 0 2000px rgba(0, 0,0, 0.5)'
            }}
          >
            <div className="d-table">
              <div className="d-table-cell">
                <div className="container">
                  <div className="main-banner-content">
                    <h1>"Empowering young minds with the keys to a digital kingdom."</h1>
                   
                   
                  </div>
                </div>
              </div>
            </div>
          </div>
        </SwiperSlide>

        <SwiperSlide>
          <div 
            className="main-banner-item"
            style={{
              backgroundImage: `url(${image3})`
            }}
          >
            <div className="d-table">
              <div className="d-table-cell">
                <div className="container">
                  <div className="main-banner-content">
                    <h1>"Closing the digital divide, one woman at a time."</h1>
                    
                    
                  </div>
                </div>
              </div>
            </div>
          </div>
        </SwiperSlide>
      </Swiper>
    </>
  );
};

export default MainBanner;
