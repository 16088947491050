import React from "react";

import shape12 from "../../../images/shape/shape12.svg";
import shape13 from "../../../images/shape/shape13.svg";
import shape14 from "../../../images/shape/shape14.png";
import shape15 from "../../../images/shape/shape15.png";
import shape2 from "../../../images/shape/shape2.png";

const ServicesCard = () => {
  return (
    <>
      <div id="services" className="services-section pt-100 pb-70">
        <div className="container">
          <div className="section-title">
            <span style={{fontWeight:'900',color:'#FF0000'}}>Services</span>
            <h3>How We Can Help?</h3>
          </div>

          <div className="row justify-content-center">
            <div 
              className="col-lg-4 col-md-6 col-sm-6"
              data-aos="fade-up" 
              data-aos-delay="100"
              data-aos-duration="800" 
              data-aos-once="true" 
            >
              <div className="single-services-box">
                <div className="icon bg-faddd4">
                  <i className="flaticon-landing-page"></i>
                </div>
                <h3>
                    Web Development
                  
                </h3>
                <p>
                We create visually stunning and functional websites that drive engagement and conversions.
                </p>
              </div>
            </div>

            <div 
              className="col-lg-4 col-md-6 col-sm-6"
              data-aos="fade-up" 
              data-aos-delay="200"
              data-aos-duration="800" 
              data-aos-once="true" 
            >
              <div className="single-services-box">
                <div className="icon bg-cafbf1">
                  <i className="flaticon-goal"></i>
                </div>
                <h3>
                  Game Development
                </h3>
                <p>
                Our team brings ideas to life through captivating and immersive gaming experiences.

                </p>
              </div>
            </div>

            <div 
              className="col-lg-4 col-md-6 col-sm-6"
              data-aos="fade-up" 
              data-aos-delay="300"
              data-aos-duration="800" 
              data-aos-once="true" 
            >
              <div className="single-services-box">
                <div className="icon bg-ddd5fb">
                  <i className="flaticon-contract"></i>
                </div>
                <h3>
                  3D Animations
                </h3>
                <p>
                From concept to execution, we produce high-quality 3D animations for various purposes.
                </p>
              </div>
            </div>

            <div 
              className="col-lg-4 col-md-6 col-sm-6"
              data-aos="fade-up" 
              data-aos-delay="400"
              data-aos-duration="800" 
              data-aos-once="true" 
            >
              <div className="single-services-box">
                <div className="icon bg-fcdeee">
                  <i className="flaticon-application"></i>
                </div>
                <h3>
                  Creative Designing
                </h3>
                <p>
                We craft visually appealing designs that resonate with your brand identity.
                </p>
              </div>
            </div>

            <div 
              className="col-lg-4 col-md-6 col-sm-6"
              data-aos="fade-up" 
              data-aos-delay="500"
              data-aos-duration="800" 
              data-aos-once="true" 
            >
              <div className="single-services-box">
                <div className="icon bg-c5ebf9">
                  <i className="flaticon-seo"></i>
                </div>
                <h3>
                  Social Media Marketing
                </h3>
                <p>
                Our strategic approach helps businesses expand their reach and maximize their online presence.

                </p>
              </div>
            </div>

            <div 
              className="col-lg-4 col-md-6 col-sm-6"
              data-aos="fade-up" 
              data-aos-delay="600"
              data-aos-duration="800" 
              data-aos-once="true" 
            >
              <div className="single-services-box">
                <div className="icon bg-f8fbd5">
                  <i className="flaticon-data-recovery"></i>
                </div>
                <h3>
                  Capacity building workshops
                </h3>
                <p>
                These contribute to the professional growth, productivity, and success of our clients and their teams.
                </p>
              </div>
            </div>
            <div 
              className="col-lg-4 col-md-6 col-sm-6"
              data-aos="fade-up" 
              data-aos-delay="600"
              data-aos-duration="800" 
              data-aos-once="true" 
            >
              <div className="single-services-box">
                <div className="icon bg-f8fbd5">
                  <i className="flaticon-data-recovery"></i>
                </div>
                <h3>
                  Digital skill Trainings
                </h3>
                <p>
                We offer comprehensive training programs designed to enhance digital literacy and proficiency among individuals and organizations.

                </p>
              </div>
            </div><div 
              className="col-lg-4 col-md-6 col-sm-6"
              data-aos="fade-up" 
              data-aos-delay="600"
              data-aos-duration="800" 
              data-aos-once="true" 
            >
              <div className="single-services-box">
                <div className="icon bg-f8fbd5">
                  <i className="flaticon-data-recovery"></i>
                </div>
                <h3>
                  Corporate Events Management
                </h3>
                <p>
                We plan and organize corporate events very carefully to make sure they run smoothly and are successful. 
                </p>
              </div>
            </div>
          </div>
        </div>

        {/* Shape Images */}
        <div className="default-animation">
          <div className="shape-img1">
            <img src={shape12} alt="image" width={21} height={20} />
          </div>
          <div className="shape-img2">
            <img src={shape13} alt="image" width={22} height={22} />
          </div>
          <div className="shape-img3">
            <img src={shape14} alt="image" width={15} height={15} />
          </div>
          <div className="shape-img4">
            <img src={shape15} alt="image" width={18} height={18} />
          </div>
          <div className="shape-img5">
            <img src={shape2} alt="shape" width={23} height={23} />
          </div>
        </div>
      </div>
    </>
  );
};

export default ServicesCard;
