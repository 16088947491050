import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination } from "swiper/modules";

import projectImg1 from "../../../images/projects/project1.jpg";
import projectImg2 from "../../../images/projects/project2.jpg";
import projectImg3 from "../../../images/projects/project3.jpg";
import projectImg4 from "../../../images/projects/project4.jpg";

const ProjectsCard = () => {
  return (
    <>
      <div id="projects" className="project-section" style={{paddingTop:'60px'}}>
        <div className="container-fluid">
          <div className="section-title">
            <span style={{fontWeight:'900'}}>Projects</span>
            <h3>Our Latest Projects</h3>
          </div>

          <Swiper
            autoHeight={true}
            spaceBetween={30}
            pagination={{
              clickable: true,
            }}
            autoplay={{
              delay: 6500,
              disableOnInteraction: true,
              pauseOnMouseEnter: true,
            }}
            breakpoints={{
              0: {
                slidesPerView: 1,
              },
              576: {
                slidesPerView: 2,
              },
              1024: {
                slidesPerView: 3,
              },
              1200: {
                slidesPerView: 4,
              },
            }}
            modules={[Autoplay, Pagination]}
            className="projects-slider"
          >
            <SwiperSlide>
              <div className="single-project-box">
                <img src={projectImg1} alt="image" width={510} height={445} />
                <div className="project-hover-content">
                  <h3>
                 
                    Basic Digital literacy Training under Pakistan Community Support Program
                    
                  </h3>
                </div>
              </div>
            </SwiperSlide>

            <SwiperSlide>
              <div className="single-project-box">
                <img src={projectImg2} alt="image" width={510} height={445} />
                <div className="project-hover-content">
                  <h3>
                  Built with AI- Hands-on Workshop
                  </h3>
                </div>
              </div>
            </SwiperSlide>

            <SwiperSlide>
              <div className="single-project-box">
                <img src={projectImg3} alt="image" width={510} height={445} />
                <div className="project-hover-content">
                  <h3>
                  Impact the future- International Women’s Day 2024
                  </h3>
                </div>
              </div>
            </SwiperSlide>

            <SwiperSlide>
              <div className="single-project-box">
                <img src={projectImg4} alt="image" width={510} height={445} />
                <div className="project-hover-content">
                  <h3>
                   Rural Youth Summit executed with The World Bank
                  </h3>
                </div>
              </div>
            </SwiperSlide>

           

            
          </Swiper>
        </div>
      </div>
    </>
  );
};

export default ProjectsCard;
