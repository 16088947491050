import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination } from "swiper/modules";

const Testimonials = () => {
  return (
    <>
      <div className="testimonial-section ptb-100">
        <div className="container">
          <div className="section-title">
            <span>Testimonial</span>
            <h3>What Our Client Say</h3>
          </div>

          <Swiper
            autoHeight={true}
            spaceBetween={30}
            pagination={{
              clickable: true,
            }}
            autoplay={{
              delay: 6500,
              disableOnInteraction: true,
              pauseOnMouseEnter: true,
            }}
            breakpoints={{
              0: {
                slidesPerView: 1,
              },
              768: {
                slidesPerView: 2,
              },
            }}
            modules={[Autoplay, Pagination]}
            className="testimonial-slides"
          >
            <SwiperSlide>
              <div className="testimonial-single-item">
                {/* <div className="testimonial-image">
                  <Image src={client1} alt="client" width={150} height={150} />
                </div> */}

                <div className="testimonial-content-text">
                  <h3>R.Mortein</h3>
                  
                  <div className="icon">
                    <i className="flaticon-quote"></i>
                  </div>
                  <p>
                  "Grow delivered exceptional event management services. Annie's leadership ensured a seamless experience from start to finish. Highly recommend for any corporate event."






                  </p>
                </div>
              </div>
            </SwiperSlide>

            <SwiperSlide>
              <div className="testimonial-single-item">
                {/* <div className="testimonial-image">
                  <Image src={client2} alt="client" width={150} height={150} />
                </div> */}

                <div className="testimonial-content-text">
                  <h3>Edward Bold</h3>
                  
                  <div className="icon">
                    <i className="flaticon-quote"></i>
                  </div>
                  <p>

                  "Grow's social media marketing services were a game-changer for our business. Annie and her team's strategic approach and creativity boosted our online presence significantly."




                  </p>
                </div>
              </div>
            </SwiperSlide>

            <SwiperSlide>
              <div className="testimonial-single-item">
                {/* <div className="testimonial-image">
                  <Image src={client3} alt="client" width={150} height={150} />
                </div> */}

                <div className="testimonial-content-text">
                  <h3>Mahindra jhon</h3>
                  <span>Ceo & Sustainable designer</span>
                  <div className="icon">
                    <i className="flaticon-quote"></i>
                  </div>
                  <p>
                  Our corporate event was a resounding success, thanks to the seamless coordination and top-notch service provided by the event management team. From concept to execution, they demonstrated professionalism and attention to detail, ensuring a memorable experience for all attendees."
                  </p>
                </div>
              </div>
            </SwiperSlide>

            

           
          </Swiper>
        </div>
      </div>
    </>
  );
};

export default Testimonials;
