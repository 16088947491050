import React from "react";
import image from "../../../images/about-image2.jpg";

const AboutContent = () => {
  return (
    <>
      <div id="about-section" className="about-section pb-100">
        <div className="container">
          <div className="row">
            <div
              className="col-lg-6 col-md-12"
              data-aos="fade-up"
              data-aos-delay="100"
              data-aos-duration="800"
              data-aos-once="true"
            >
              <div
                className="about-warp-image"
                style={{ backgroundImage: `url(${image})`,
              backgroundSize:'cover',
            backgroundPosition:'right center ' }}
              ></div>
            </div>

            <div
              className="col-lg-6 col-md-12"
              data-aos="fade-up"
              data-aos-delay="200"
              data-aos-duration="800"
              data-aos-once="true"
            >
              <div className="about-warp pl-15">
                <span style={{fontWeight:'900',color:'ff0000'}}>About Us</span>
                <h3>What is Grow?</h3>
<p style={{fontSize:'1rem', fontWeight:'bold',lineHeight:'2.5rem'}}>
  
  
                  
                 " Welcome to Grow, a women-owned platform, with more than eight years of experience catering to global clients with innovative services and solutions. At Grow, our mission is to empower individuals and businesses through our technical expertise, comprehensive digital skills training, outstanding event management services, and valuable partnerships.
                  At Grow, we recognize the power of collaboration. "
                <br/> <br/>
                  Join us at Grow, where we're not just building businesses – we're building a community of empowered individuals poised for success in the digital era.
                   </p>
                

                

               
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AboutContent;
