import React from "react";


const Features = () => {
  return (
    <>
      <div className="features-section bg-color">
        <div className="container">
          <div className="row justify-content-center">
            <div> <h1> Grow Journey of Impact</h1> </div>
            <div 
              className="col-lg-4 col-md-6"
              data-aos="fade-up" 
              data-aos-delay="100"
              data-aos-duration="800" 
              data-aos-once="true" 
            >
              <div className="single-features-item-two">
               
                <h3 style={{fontWeight:'900',fontSize:'3rem'}}>
                  1000+
                </h3>
                <p>
                Youth trained on digital skills
                </p>
              </div>
            </div>

            <div 
              className="col-lg-4 col-md-6"
              data-aos="fade-up" 
              data-aos-delay="200"
              data-aos-duration="800" 
              data-aos-once="true" 
            >
              <div className="single-features-item-two">
               
                <h3 style={{fontWeight:'900',fontSize:'3rem'}}>
                  50+
                </h3>
                <p>
                  Startups mentored
                </p>
              </div>
            </div>

            <div 
              className="col-lg-4 col-md-6"
              data-aos="fade-up" 
              data-aos-delay="300"
              data-aos-duration="800" 
              data-aos-once="true" 
            >
              <div className="single-features-item-two">
                
                <h3 style={{fontWeight:'900',fontSize:'3rem'}}>
                  40+
                </h3>
                <p>
                Corporate events & community meetups 

                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Features;
