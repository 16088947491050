import React from "react";
import { Link } from "react-scroll";

import shape12 from "../../../images/shape/shape12.svg";
import shape13 from "../../../images/shape/shape13.svg";
import shape14 from "../../../images/shape/shape14.png";
import shape15 from "../../../images/shape/shape15.png";
import shape2 from "../../../images/shape/shape2.png";

const Footer = () => {
  const currentYear = new Date().getFullYear();
  return (
    <>
      <footer className="footer-section pt-100 pb-70">
        <div className="container">
          <div className="row">
            <div className="col-lg-4 col-md-6 col-sm-6">
              <div className="footer-area">
                <div className="footer-heading">
                  <h3>About Us</h3>
                </div>

                <p>
                Welcome to Grow, a women-owned platform, with more than eight years of experience catering to global clients with innovative services and solutions. At Grow, our mission is to empower individuals and businesses through our technical expertise, comprehensive digital skills training, outstanding event management services, and valuable partnerships.
                </p>

                {/* <ul className="footer-social">
                  <li>
                    <a
                      href="https://www.linkedin.com/"
                      target="_blank"
                      className="bg-3955bc"
                    >
                      <i className="fab fa-linkedin-in"></i>
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.facebook.com/"
                      target="_blank"
                      className="bg-1da1f2"
                    >
                      <i className="fab fa-facebook-f"></i>
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://twitter.com/"
                      target="_blank"
                      className="bg-004dff"
                    >
                      <i className="fab fa-twitter"></i>
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.instagram.com/"
                      target="_blank"
                      className="bg-0273af"
                    >
                      <i className="fab fa-instagram"></i>
                    </a>
                  </li>
                </ul> */}
              </div>
            </div>

            <div className="col-lg-2 col-md-6 col-sm-6">
              <div className="footer-item-area">
                <div className="footer-heading">
                  <h3>Important Links</h3>
                </div>

                <ul className="footer-quick-links">
                  <li>
                    <Link activeClass="active" 
                      to="about-section" 
                      spy={true} 
                      smooth={true} 
                      offset={50} 
                      duration={500} 
                      style={{cursor:'pointer'}}>
                        
                      About Us</Link>
                  </li>
                  <li>
                    <Link to="projects" 
                      spy={true} 
                      smooth={true} 
                      offset={50} 
                      duration={500} 
                      style={{cursor:'pointer'}}>Projects</Link>
                  </li>
                  <li>
                    <Link to="services" 
                      spy={true} 
                      smooth={true} 
                      offset={50} 
                      duration={500} 
                      style={{cursor:'pointer'}}>Services</Link>
                  </li>
                  <li>
                    <Link to="team" 
                      spy={true} 
                      smooth={true} 
                      offset={50} 
                      duration={500} 
                      style={{cursor:'pointer'}}>Team</Link>
                  </li>
                 
                </ul>
              </div>
            </div>

            <div className="col-lg-2 col-md-6 col-sm-6">
              <div className="footer-item-area">
                <div className="footer-heading">
                  <h3>Featured Services</h3>
                </div>

                <ul className="footer-quick-links">
                  <li>
                  <p style={{color:'white'}}> Web Development </p>
                  </li>
                  <li>
                  <p style={{color:'white'}}> Game Development </p>
                  </li>
                  <li>
                  <p style={{color:'white'}}> Social Media Marketing </p>
                  </li>
                  <li>
                   <p style={{color:'white'}}> Digital Skills Training</p>
                  </li>
                  <li>
                  <p style={{color:'white'}}> Corporate Event Management </p>
                  </li>
                </ul>
              </div>
            </div>

            <div className="col-lg-4 col-md-6 col-sm-6">
              <div className="mb-30">
                <div className="footer-heading">
                  <h3>Contact</h3>
                </div>

                <div className="footer-info-contact">
                  <i className="flaticon-call-answer"></i>
                  <h3>Phone</h3>
                  <span>(+92) 3300-120000</span>
                </div>

                <div className="footer-info-contact">
                  <i className="flaticon-envelope"></i>
                  <h3>Email</h3>
                  <span>anniegul.me@gmail.co</span>
                </div>

                <div className="footer-info-contact">
                  <i className="flaticon-placeholder-filled-point"></i>
                  <h3>Address</h3>
                  <span>Street C-8, Second Floor, Spinzar IT Tower, Peshawar,Pakistan  <br/><br/> D12/1 Islamabad,Pakistan</span>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Shape Images */}
        <div className="default-animation">
          <div className="shape-img1">
            <img src={shape12} alt="image" width={21} height={20} />
          </div>
          <div className="shape-img2">
            <img src={shape13} alt="image" width={22} height={22} />
          </div>
          <div className="shape-img3">
            <img src={shape14} alt="image" width={15} height={15} />
          </div>
          <div className="shape-img4">
            <img src={shape15} alt="image" width={18} height={18} />
          </div>
          <div className="shape-img5">
            <img src={shape2} alt="image" width={23} height={23} />
          </div>
        </div>
      </footer>

      {/* Copyright footer */}
      {/* <div className="copyright-area">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6">
              <p>
                © {currentYear} Neemo. All Rights Reserved by{" "}
                <a href="https://envytheme.com/" target="_blank">
                  EnvyTheme
                </a>
              </p>
            </div>

            <div className="col-lg-6">
              <ul>
                <li>
                  <Link href="/terms-conditions">Terms & Conditions</Link>
                </li>
                <li>
                  <Link href="/privacy-policy">Privacy Policy</Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div> */}
    </>
  );
};

export default Footer;
