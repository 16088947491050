import React from "react";
import NavBar from "./Navbar";
import MainBanner from "./Banner";
import Features from "./Features";
import AboutContent from "./AboutContent";
import ServicesCard from "./ServicesCard";
import WhyChooseUs from "./WhyChooseUs";
import ProjectsCard from "./ProjectsCard";
import TeamCardTwo from "./TeamCard";
import Testimonials from "./Testimonials";
import Footer from "./Footer";

export default function Home() {
  return (
    <>
      <NavBar />

<MainBanner />

<Features />

<AboutContent />

<WhyChooseUs />

<ServicesCard />

<ProjectsCard />

<TeamCardTwo />

<Testimonials />



<div className="pb-100">
  {/* <PartnerLogos /> */}
</div>



<Footer />
    </>
  );
}