import React from "react";


const WhyChooseUs = () => {
  return (
    <>
      <div className="choose-section pt-5" >
        <div className="container"  >
          <div className="row" >
            <div 
              className="col-lg-12 col-md-12  "
              data-aos="fade-up"
              data-aos-delay="100"
              data-aos-duration="800"
              data-aos-once="true"
              
            >
              <div className="choose-content-warp" >
                
                <h3>The Grow Story</h3>
                <p style={{fontWeight:'bold'}}>
                In the bustling streets of Khyber Pakhtunkwa Pakistan, Grow emerged as a dynamic and innovative women-owned entity dedicated to empowering youth, specifically women and making a positive impact on society.  <br/> <br/>

Recognized and appreciated by AnitaB.org, Grow’s founder has worked tirelessly to transform women's lives through digital literacy, financial inclusion, and entrepreneurial skills. With expertise in web development, game development, 3D animations, creative designing, and social media marketing, Grow specializes in creating immersive digital experiences that empower women to thrive in today's digital age. 
<br/> <br/>
Collaborating closely with organizations like the World Bank, Grow is offering comprehensive digital skills training programs for capacity building of youth to inspire them to seize the boundless opportunities presented by the digital world.

                </p>

                
              </div>
            </div>

            <div 
              className="col-lg-6 col-md-12"
              data-aos="fade-up"
              data-aos-delay="200"
              data-aos-duration="800"
              data-aos-once="true"
            >
              
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default WhyChooseUs;
