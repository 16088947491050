import Home from './components/ReactApp/Layout/index';
import 'bootstrap/dist/css/bootstrap.min.css';
import "./styles/style.css";
import "./styles/responsive.css";
import "./styles/flaticon.css";
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'font-awesome/css/font-awesome.min.css';

function App() {
  return (
    <Home/>
  );
}

export default App;
